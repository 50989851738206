<script setup lang="ts">
import IconCheck from '@/assets/icons/check.svg'

const features = [
  'News and analysis of the latest market developments',
  'Data visualisations, charts, and supply chain maps',
  'Insights from Benchmark’s analysts',
  'Benchmark presentations, videos, and quarterly review',
]
</script>

<template>
  <h1 class="mb-5 text-2xl font-bold md:mb-10 lg:text-4xl">
    Everything starts at Benchmark Source
  </h1>
  <ul class="mt-6">
    <li v-for="feature in features" :key="feature" class="mt-1 flex gap-2">
      <IconCheck class="mt-[.3rem] shrink-0 text-green" />
      <p class="text-lg leading-6">{{ feature }}</p>
    </li>
  </ul>
</template>
