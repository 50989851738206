<script setup lang="ts">
const props = defineProps({
  videoId: {
    type: [Number, String],
    required: true,
  },
  autoplay: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
})

const routeVideo = computed(() => {
  return `https://player.vimeo.com/video/${props.videoId}?loop=false&autoplay=${props.autoplay}&muted=false&playsinline=true&byline=false&portrait=false&title=false&speed=true&transparent=false&&captions=false&pip=false&color=FDBD00`
})
</script>

<template>
  <div class="flex aspect-video w-full">
    <iframe
      :src="routeVideo"
      allowfullscreen
      class="h-full w-full"
      allow="autoplay; fullscreen; picture-in-picture; encrypted-media; accelerometer; gyroscope"
      :title="title"
    />
  </div>
</template>
